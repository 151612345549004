<template>
  <el-card class="form-container" shadow="never" style="width: 98%">
    <el-form :model="userSettingModel" ref="userSettingForm" label-width="200px">
      <el-form-item label="用户下载次数超过：" >
        <el-input v-model="userSettingModel.down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许下载</span>
      </el-form-item>
      <el-form-item label="用户预览次数超过：" >
        <el-input v-model="userSettingModel.preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
        <span style="margin-left: 10px;">不允许预览</span>
      </el-form-item>
      <el-form-item label="会员金额：">
        <el-input v-model="userSettingModel.member_money" type="number" style="width: 30%">
          <template v-slot:append>元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="给分享者增加下载次数：" >
        <el-input v-model="userSettingModel.super_down_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
      </el-form-item>
      <el-form-item label="给分享者增加预览次数：" >
        <el-input v-model="userSettingModel.super_preview_number" min="0" type="number" style="width: 30%">
          <template v-slot:append>次</template>
        </el-input>
      </el-form-item>
      <el-form-item label="背景色：">
        <el-color-picker v-model="userSettingModel.back_color" style="width: 300px;"></el-color-picker>
      </el-form-item>
      <el-form-item label="文案：" >
        <el-input v-model="userSettingModel.back_content" style="width: 30%" clearable></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8" v-if="!userSettingModel.back_pic" >
          <el-form-item label="小程序背景图：" >
            <el-upload ref="uploadForm" v-model="userSettingModel.back_pic" drag
                       action="#"
                       :file-list="fileListShow"
                       :auto-upload="false"
                       :before-remove="handleRemove"
                       :on-change="fileChange"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="userSettingModel.back_pic" >
          <el-form-item label="小程序背景图：" >
            <img style="width: 100%" :src="userSettingModel.back_pic"  class="avatar" >
            <span @click="handleDelete">删除</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8" v-if="!userSettingModel.pc_back_pic" >
          <el-form-item label="电脑端背景图：" >
            <div class="el-upload__tip" slot="tip">请上传尺寸为宽1410、高830的图片</div>
            <el-upload ref="uploadForm" v-model="userSettingModel.pc_back_pic" drag
                       action="#"
                       :file-list="fileListShowPC"
                       :auto-upload="false"
                       :before-remove="handleRemovePC"
                       :on-change="fileChangePC"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="userSettingModel.pc_back_pic" >
          <el-form-item label="电脑端背景图：" >
            <img style="width: 100%" :src="userSettingModel.pc_back_pic"  class="avatar" >
            <span @click="handleDeletePC">删除</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8" v-if="!userSettingModel.video_url" >
          <el-form-item label="视频：" >
            <el-upload ref="uploadForm" v-model="userSettingModel.video_url" drag
                       action="#"
                       :file-list="fileListShowVideo"
                       :auto-upload="false"
                       :before-remove="handleRemoveVideo"
                       :on-change="fileChangeVideo"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="userSettingModel.video_url" >
          <el-form-item label="视频：" >
            <video controls style="width: 100%;height: 260px;margin: 0px auto"  :src="userSettingModel.video_url">
            </video>
            <span @click="handleDeleteVideo">删除</span>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item>
        <el-button type="primary" @click="handleDialogConfirm('userSettingForm')">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>

import {getUserSetting, updateUserSetting} from '@/api/userSetting';

const defaultUserSetting = {
  id: null,
  down_number: 0,
  preview_number: 0,
  member_money: 0,
  super_down_number: 0,
  super_preview_number: 0,
  back_color: "",
  back_pic:"",
  pc_back_pic:"",
  back_content:"",
  video_url:""
};

export default {
  name: 'userSetting',
  components: {},
  data() {
    return {
      userSettingModel: Object.assign({}, defaultUserSetting),

      fileListShow:[],
      fileListShowPC:[],
      fileListShowVideo:[],
      fileList:[],
      fileListPC:[],
      fileListVideo:[],
      editProductPic:"",
      editProductPicPC:"",
      editProductVideo:"",

    }
  },
  created() {

    this.getDetail();
  },
  methods: {
    //文件上传
    fileChange(file, fileList) {
      this.fileList = [];
      this.fileList.push(file);
      this.fileListShow = [];
      this.fileListShow.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemove(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileList = this.fileList.filter(item => item.name !== file.name);
    },
    //文件上传
    fileChangePC(file, fileList) {
      this.fileListPC = [];
      this.fileListPC.push(file);
      this.fileListShowPC = [];
      this.fileListShowPC.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemovePC(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileListPC = this.fileListPC.filter(item => item.name !== file.name);
    },
    //文件上传
    fileChangeVideo(file, fileList) {
      this.fileListVideo = [];
      this.fileListVideo.push(file);
      this.fileListShowVideo = [];
      this.fileListShowVideo.push({
        name: file["name"],
      })

    },
    //文件删除
    handleRemoveVideo(file, fileList) {
      // 通过file.name或者其他属性来匹配需要删除的文件
      this.fileListVideo = this.fileListVideo.filter(item => item.name !== file.name);
    },

    handleDelete(){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.editProductPic = this.userSettingModel.back_pic;
        this.userSettingModel.back_pic = "";
        this.$forceUpdate();   // 强制更新

      });
    },
    handleDeletePC(){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.editProductPicPC = this.userSettingModel.pc_back_pic;
        this.userSettingModel.pc_back_pic = "";
        this.$forceUpdate();   // 强制更新

      });
    },
    handleDeleteVideo(){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        //删除附件
        this.editProductVideo = this.userSettingModel.video_url;
        this.userSettingModel.video_url = "";
        this.$forceUpdate();   // 强制更新

      });
    },
    getDetail(){
      let data =  {
        "dataId": 1,
      };
      getUserSetting(data).then(response=>{
            if (response.code === '0') {
              this.userSettingModel = response.data;
            }
      })
    },
    //提交操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (!this.userSettingModel.member_money || this.userSettingModel.member_money <=0) {
            this.$message.error("请填写会员金额")
            return
          }

          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {


            const form = new FormData();
            for (let i = 0; i < this.fileList.length; i++) {
              form.append('file[]', this.fileList[i].raw);
            }
            for (let i = 0; i < this.fileListPC.length; i++) {
              form.append('filePC[]', this.fileListPC[i].raw);
            }
            for (let i = 0; i < this.fileListVideo.length; i++) {
              form.append('fileVideo[]', this.fileListVideo[i].raw);
            }
            form.append('id',this.userSettingModel.id);
            form.append('down_number',this.userSettingModel.down_number);
            form.append('preview_number',this.userSettingModel.preview_number);
            form.append('member_money',this.userSettingModel.member_money);
            form.append('super_down_number',this.userSettingModel.super_down_number);
            form.append('super_preview_number',this.userSettingModel.super_preview_number);
            form.append('back_content',this.userSettingModel.back_content);
            form.append('back_color',this.userSettingModel.back_color);
            form.append('back_pic',this.userSettingModel.back_pic);
            form.append('pc_back_pic',this.userSettingModel.pc_back_pic);
            form.append('video_url',this.userSettingModel.video_url);
            form.append('delete_product_pic',this.editProductPic);
            form.append('delete_pc_product_pic',this.editProductPicPC);
            form.append('delete_video_url',this.editProductVideo);

            updateUserSetting(form).then(res => {
              if (res.code === '0') {
                this.$message({
                  message: '修改成功',
                  type: 'success',
                });
              }else {
                this.$message({
                  type: "error",
                  message: res.msg
                })
              }
              this.getDetail();
              this.fileList = [];
              this.fileListShow = [];
            });

          });
        }
      });
    },

  }
}
</script>
<style scoped>

</style>
