import request from '@/utils/request';

//详情
export function getUserSetting(data) {
  return request({
    url:'/user/setting/detail.php',
    method : 'post',
    data : data
  })
}

//修改
export function updateUserSetting(form) {
  const data = form
  return request({
    url :'/user/setting/updateSetting.php',
    method : 'post',
    headers: {'Content-Type':'application/x-www-form-urlencoded'},
    data
  })
}
